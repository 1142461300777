<template>
  <div class="p-2">
    <div v-if="showError" class="w-100 text-center pt-4">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>
    <template v-else>
      <b-row class="match-height" align-h="center">
        <b-col md="8">
          <b-card class="">
            <h3 class="mb-0">Staff Registration</h3>
            <hr />

            <b-row align-h="center">
              <!-- <b-col cols="4"> </b-col> -->
              <b-col class="ml-3" cols="4">
                <b-form-group
                  class="text-center"
                  ref="picture"
                  label=" "
                  label-for="name-input"
                  invalid-feedback="Picture is required."
                >
                  <b-media no-body ref="picture">
                    <div v-if="logoloading == true">
                      <b-spinner
                        variant="primary"
                        type="grow"
                        class="m-5"
                        label="Spinning"
                      ></b-spinner>
                    </div>

                    <b-media-aside>
                      <b-link>
                        <b-img
                          ref="previewEl"
                          src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                          @click="$refs.fileProfile.click()"
                          v-if="logoloading == false"
                          style="border-radius: 100px"
                          fluid
                          height="120px"
                          width="120px"
                        />
                      </b-link>

                      <b-link v-if="logoloading == 'loaded'">
                        <div class="text-right" style="margin-right: 35px">
                          <b-button
                            @click="deleteLogo()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="position-absolute btn-icon"
                            variant="danger"
                          >
                            <feather-icon icon="Trash2Icon" />
                          </b-button>
                        </div>

                        <b-img
                          class="rcorners2"
                          :src="myObj.picture"
                          height="120px"
                          width="120px"
                        />

                        <br />
                      </b-link>
                      <input
                        type="file"
                        id="fileProfile"
                        hidden
                        ref="fileProfile"
                        @change="selectlogo()"
                        accept="image/*"
                      />
                    </b-media-aside>
                  </b-media>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="4"> </b-col> -->
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Campus *"
                  invalid-feedback="Campus is required."
                  ref="camp"
                >
                  <v-select
                    v-model="myObj.campusID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="campuses"
                    :clearable="false"
                    :reduce="(val) => val.id"
                    label="branch"
                    placeholder="Select campus"
                    @input="LoadDep()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Name *"
                  invalid-feedback="Staff name is required."
                >
                  <b-form-input
                    placeholder="Enter name."
                    ref="name"
                    v-model.trim="myObj.name"
                    @focusout="CheckName()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Father name"
                  invalid-feedback="Father name is required."
                >
                  <b-form-input
                    ref="fname"
                    placeholder="Enter father name."
                    v-model.trim="myObj.father_name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Spouse name"
                  invalid-feedback="Spouse name is required."
                >
                  <b-form-input
                    ref="spouse"
                    placeholder="Enter spouse name."
                    v-model="myObj.spouse"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Gender"
                  invalid-feedback="School name is required."
                >
                  <v-select
                    v-model="myObj.gender"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genders"
                    :clearable="false"
                    input-id="categoryId"
                    ref="category"
                    placeholder="Select gender"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date of Birth">
                  <flat-pickr
                    :config="config"
                    v-model="myObj.date_of_birth"
                    class="form-control"
                    placeholder="Select date of birth."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Contact *"
                  invalid-feedback="Contact number is required."
                >
                  <vue-tel-input
                    v-model="myObj.contact_number"
                    :inputOptions="vueTelOptions"
                    @input="CheckSContact"
                  ></vue-tel-input>
                  <small class="mr-1 text-danger" v-if="sPhoneerror"
                    >Phone number is required</small
                  >
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="CNIC no"
                  invalid-feedback="CNIC no is required."
                >
                  <b-form-input
                    placeholder="Enter cnic number"
                    v-model.trim="myObj.cnic"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Qualification."
                  invalid-feedback="qualification is required."
                >
                  <b-form-input
                    ref="qua"
                    placeholder="Enter qualification."
                    v-model.trim="myObj.qualification"
                  />
                  <!-- @focusout="CheckQualification()" -->
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Department *"
                  invalid-feedback="department is required."
                  ref="dep"
                >
                  <v-select
                    v-model="myObj.departmentID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="deptOptions"
                    :reduce="(opt) => opt.id"
                    label="name"
                    :clearable="false"
                    @input="checkDep()"
                    :disabled="depLoading"
                    placeholder="Select department"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Designation"
                  invalid-feedback="designation is required."
                >
                  <b-form-input
                    ref="design"
                    placeholder="Enter designation."
                    v-model="myObj.designation"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Experience"
                  invalid-feedback="Experience is required."
                >
                  <b-form-input
                    placeholder="Enter experience."
                    v-model.trim="myObj.experience"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Note" invalid-feedback="Note is required.">
                  <b-form-input placeholder="Enter note" v-model="myObj.note" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Current Address"
                  invalid-feedback="Contact number is required."
                >
                  <b-form-input
                    placeholder="Enter current address."
                    v-model="myObj.address_current"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Permanent Address"
                  invalid-feedback="Contact number is required."
                >
                  <b-form-input
                    placeholder="Enter permanent address."
                    v-model="myObj.address_permanent"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="8">
          <b-card class="">
            <h3 class="mt-1">Emergency Information</h3>
            <hr />
            <b-row>
              <b-col md="12">
                <b-form-group label="Name" invalid-feedback="Name is required.">
                  <b-form-input
                    ref="emerName"
                    placeholder="Enter name."
                    v-model="myObj.emergency_contact_person"
                  />
                  <!-- @focusout="CheckEmerName()" -->
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Relation"
                  invalid-feedback="Relation is required."
                >
                  <b-form-input
                    ref="emerRel"
                    placeholder="Enter relation."
                    v-model="myObj.emergency_contact_relation"
                  />
                  <!-- @focusout="CheckEmerRelation()" -->
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Emergency contact"
                  invalid-feedback="Emergency Contact is required."
                >
                  <vue-tel-input
                    v-model="myObj.emergency_number"
                    :inputOptions="emerOpt"
                  ></vue-tel-input>
                  <!-- @blur="CheckEmerContact()" -->
                  <small class="mr-1 text-danger" v-if="emerPhoneerror"
                    >Phone number is required.</small
                  >
                </b-form-group>
              </b-col>
            </b-row>
            <b-row> </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col md="6">
          <b-button
            block
            variant="success"
            :disabled="submitting"
            @click="formSubmitted()"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import {
  VBTooltip,
  BRow,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BTable,
  BForm,
  BSidebar,
  BAvatar,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BFormCheckboxGroup,
    BFormCheckbox,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    BFormTextarea,
    BTable,
    BForm,
    BSidebar,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      submitting: false,
      sPhoneerror: false,
      emerPhoneerror: false,
      deptOptions: [],
      campuses: [],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      vueTelOptions: { placeholder: "Enter contact number" },
      emerOpt: { placeholder: "Enter emergency contact" },
      logoloading: false,
      myObj: {
        id: 0,
        rfID: "",
        name: "",
        father_name: "",
        designation: "",
        gender: "",
        picture: "",
        date_of_birth: null,
        address_current: "",
        address_permanent: "",
        contact_number: "",
        emergency_contact_person: "",
        emergency_contact_relation: "",
        emergency_number: "",
        qualification: "",
        experience: "",
        campusID: 0,
        departmentID: 0,
        documents: "",
        appointedOn: null,
        totalSalary: 0,
        basicSalary: 0,
        expectedSalary: 0,
        sharePerc: 0,
        payMode: null,
        accountTitle: null,
        accountNumber: null,
        cnic: "",
        spouse: "",
        isCoordinator: false,
        leaves_allowed_from: null,
        card_number: null,
        holdSalary: false,
        accountID: 0,
        empCode: 0,
        registrationDate: new Date().toJSON(),
        branchID: 0,
        status: "present",
        leaving_date: null,
        rejoining_date: null,
      },
      genders: ["Male", "Female"],
      depLoading: false,
      showError: false,
      db: "",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiYTdjYzU5YTEtZTRhOC00MmM1LWEzMmYtY2EzODM4ZTc0MDU4IiwiZXhwIjoyMDMwNjkyODMyLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0.xuRNNjiKeO7158I-_Yni-rQUcl__LK6_oOtOwo9XfGs",
    };
  },
  created() {
    if (!this.$route.query.db) {
      this.showError = true;
    } else {
      this.db = this.$route.query.db;
      this.LoadCampuses();
    }
  },
  computed: {
    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
      }
      return img;
    },
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    async LoadCampuses() {
      var obj = {
        url: this.$store.state.domain + "campuses?db=" + this.db + "&cId=1",
        token: this.token,
      };
      let res = await this.get(obj);
      if (res.length > 0) {
        this.campuses = res;
      }
    },
    async LoadDep() {
      if (this.myObj.campusID > 0) {
        this.depLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "StaffDepartments?db=" +
            this.db +
            "&cID=" +
            this.myObj.campusID,
          token: this.token,
        };
        this.deptOptions = await this.get(obj);
        this.depLoading = false;

        let dept = this.deptOptions.find(
          (x) => x.name.toLowerCase() == "staff"
        );
        this.myObj.departmentID = dept ? dept.id : 0;
      }
    },

    selectlogo() {
      // console.log(this.$refs.fileProfile.files[0]);
      this.fileProfile = this.$refs.fileProfile.files[0];
      // console.log(this.fileProfile);
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        var axios = require("axios");
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.myskool.app/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            var fn1 = e.data.file.path;
            this.myObj.picture = fn1;
            this.logoloading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.logoloading = false;
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.picture = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCampus() {
      var elem = this.$refs["camp"];
      if (this.myObj.campusID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    CheckAppointment() {
      if (this.localID > 0 && this.formRights.hidePayroll) {
        return true;
      } else {
        var elem = this.$refs["appoint_date"];
        if (!this.myObj.appointedOn) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckQualification() {
      var elem = this.$refs["qua"];
      if (this.myObj.qualification == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDesignation() {
      var elem = this.$refs["design"];
      if (this.myObj.designation == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFContact() {
      // console.log(this.myObj.contact_father);
      // console.log(this.myObj.contact_father.length);
      if (this.myObj.contact_father.length == 12) {
        this.fphoneerror = false;
        return true;
      } else {
        this.fphoneerror = true;
        return false;
      }
    },
    CheckMContact() {
      // console.log(this.myObj.contact_mother);
      // console.log(this.myObj.contact_mother.length);
      if (this.myObj.contact_mother.length == 12) {
        this.mphoneerror = false;
        return true;
      } else {
        this.mphoneerror = true;
        return false;
      }
    },
    CheckContact() {
      // console.log(this.myObj.contact_student);
      // console.log(this.myObj.contact_student.length);
      if (this.myObj.contact_student.length == 12) {
        this.phoneerror = false;
        return true;
      } else {
        this.phoneerror = true;
        return false;
      }
    },
    CheckSContact(s, e) {
      // console.log(this.myObj.contact_number);
      // console.log(this.myObj.contact_student.length);
      // if (this.myObj.contact_number && this.myObj.contact_number.length == 12) {
      //   this.sPhoneerror = false;
      //   return true;
      // } else {
      //   this.sPhoneerror = true;
      //   return false;
      // }

      if (this.myObj.contact_number) {
        if (e.valid) {
          this.sPhoneerror = false;
          this.myObj.contact_number = e.number;
          // console.log(this.myObj.contact_number);
        } else {
          this.sPhoneerror = true;
        }
      } else {
        this.sPhoneerror = false;
      }
    },

    CheckEmerName() {
      var elem = this.$refs["emerName"];
      if (
        this.myObj.emergency_name == null ||
        this.myObj.emergency_name == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDep() {
      var elem = this.$refs["dep"];
      if (this.myObj.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerRelation() {
      var elem = this.$refs["emerRel"];
      if (
        this.myObj.emergency_relationship == null ||
        this.myObj.emergency_relationship == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerContact() {
      if (this.myObj.emergency_contact.length == 12) {
        // console.log(this.myObj.emergency_contact.length);
        this.emerPhoneerror = false;
        return true;
      } else {
        this.emerPhoneerror = true;
        return false;
      }
    },
    cleanStates() {
      this.myObj = {
        id: 0,
        rfID: "",
        name: "",
        father_name: "",
        designation: "",
        gender: "",
        picture: "",
        date_of_birth: null,
        address_current: "",
        address_permanent: "",
        contact_number: "",
        emergency_contact_person: "",
        emergency_contact_relation: "",
        emergency_number: "",
        qualification: "",
        experience: "",
        campusID: this.myObj.campusID,
        departmentID: this.myObj.departmentID,
        documents: "",
        appointedOn: null,
        totalSalary: 0,
        basicSalary: 0,
        expectedSalary: 0,
        sharePerc: 0,
        payMode: null,
        accountTitle: null,
        accountNumber: null,
        cnic: "",
        spouse: "",
        isCoordinator: false,
        leaves_allowed_from: null,
        card_number: null,
        holdSalary: false,
        accountID: 0,
        empCode: 0,
        registrationDate: new Date().toJSON(),
        branchID: 0,
        status: "present",
        leaving_date: null,
        rejoining_date: null,
      };
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["camp"];
      elem.state = undefined;
      var elem = this.$refs["dep"];
      elem.state = undefined;
      setTimeout(() => {
        this.sPhoneerror = false;
      }, 500);
    },
    async formSubmitted() {
      this.submitting = true;
      this.CheckCampus();
      this.CheckName();
      this.checkDep();
      if (!this.myObj.contact_number) this.sPhoneerror = true;

      if (this.CheckCampus() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Please select the campus", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.checkDep() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Please select staff department.", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.CheckName() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Invalid name entered!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (!this.myObj.contact_number || this.sPhoneerror) {
        this.submitting = false;
        return this.$bvToast.toast("Invalid contact entered!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        if (this.myObj.date_of_birth == "") {
          this.myObj.date_of_birth = null;
        }
        let dt = new Date().toJSON();
        this.myObj.registrationDate = dt;
        this.myObj.appointedOn = dt;
        this.myObj.leaves_allowed_from = dt;

        let saveObj = {
          staff: this.myObj,
          timings: [],
          incrementHistory: [],
          staffAllowances: [],
          staffDeductions: [],
          advanceAndLoans: [],
          securityDeposit: [],
          policy: [],
          loanDetails: [],
          securityDepositGrid: [],
          classShare: [],
        };
        // console.log(saveObj);
        var message = "Staff added successfully.";
        if (this.myObj.id > 0) message = "Staff updated successfully.";

        // "staff/SaveWithPolicy?db=" +
        var status = await this.post({
          url: this.$store.state.domain + "staff/SaveWithShare?db=" + this.db,
          body: saveObj,
          message: message,
          context: this,
          token: this.token,
          registration: true,
        });
        this.submitting = false;
        if (status) {
          Swal.fire({
            title: "Succes",
            text: "Thank you for submitting the form!",
            icon: "success",
          });
          this.cleanStates();
        }
      }
    },
  },
};
</script>
<style scoped>
.rcorners2 {
  border-radius: 100px;
  border: 4px solid var(--primary);
  padding: 2px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
